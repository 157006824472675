import { Component } from '@angular/core';
import packageJson from '../../../../../package.json';
@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <div class="created-by">
    <a class="logo-bbm-footer" href="#"><img src="./assets/images/logo-bolsa.svg" /></a> 
    <p class="d-inline-block pl-2 mb-0"><small>{{year}} | Versão {{version}}</small></p>
    </div>
  `,
})
export class FooterComponent {
  public year = new Date().getFullYear();
  public version: string = packageJson.version;
}
