// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api: "https://bbmnet-qa-djttwzvvlq-uc.a.run.app",´
  api: "Não é utilizado",
  apiCadastroParticipantes: "https://cadastro-participantes-backend-rvdavvn5uq-uc.a.run.app",
  buscaCepApi: "https://viacep.com.br/ws/",
  frontRef: 'https://cad-admin-dev.novobbmnet.com.br',
  kc: "https://pitang-bbm-cadastro-dev.uc.r.appspot.com/auth/",
  keyCripto: 'S96bLOCR8dh16isOWVQABA8nv+h3WfDB',
  criptoLocalKey: 'PO6EEOCR8Hh16iWOWVQAER8nv+h3WfTB'
};

