import { Globals } from './../globals';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { TIPO_PARTICIPANTE } from '../enums/tipoParticipante.enum';
import { CriptoLocalStorageService } from '../services/cripto-localstorage/cripto-localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class RolesGuard implements CanActivate {
  public rolesUser: any;
  private logged = false;
  public keycloakInstance: any
  constructor(private cookieService: CriptoLocalStorageService, protected router: Router, private globals: Globals, protected readonly keycloak: KeycloakService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkPermissoes(route);
  }
  checkPermissoes(route: ActivatedRouteSnapshot): boolean {
    this.keycloakInstance = this.keycloak.getKeycloakInstance();
    let permission = false;
    if (typeof route.data['roles'] != undefined && route.data['roles'].length > 0 && route.data['path'].length > 0 && this.cookieService.check('perfilParticipante')) {
      const routeRoles = route.data['roles'];
      const pathRoles: Array<any> = route.data['path'];
      routeRoles.forEach((routeRole: any) => {
        if (this.cookieService.get('perfilParticipante').toLowerCase() == routeRole.toLowerCase()) {
          permission = true;
        }
      });
      pathRoles.forEach((role) => {
        if (this.cookieService.get('tipoParticipante').toLowerCase() == TIPO_PARTICIPANTE.BBM.toLowerCase()) {
          permission = true;
        }
      })
      if (!permission) { this.router.navigateByUrl('/error/nao-autorizado'); }
    } else {
      this.router.navigateByUrl('/error/nao-autorizado');
    }
    return permission;
  }

}
