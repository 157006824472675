import { Globals } from '../globals';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { TIPO_PARTICIPANTE } from '../enums/tipoParticipante.enum';
import { PERFIL_PARTICIPANTE } from '../enums/perfilParticipante.enum';
import { CriptoLocalStorageService } from '../services/cripto-localstorage/cripto-localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class PathGuard implements CanActivate {
  public rolesUser: any;
  private logged = false;
  public user: any;
  public keycloakInstance: any
  constructor(private cookieService: CriptoLocalStorageService, protected router: Router, private globals: Globals, protected readonly keycloak: KeycloakService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkPermissoes(route);
  }
  checkPermissoes(route: ActivatedRouteSnapshot): boolean {
    this.user = this.keycloak.getKeycloakInstance().idTokenParsed;
    this.keycloakInstance = this.keycloak.getKeycloakInstance();
    let permission = false;
    if (typeof route.data['path'] != undefined && route.data['path'].length && this.cookieService.check('tipoParticipante')) {
      const routePath: Array<string> = route.data['path'];
      const routeRoles: Array<string> = route.data['roles'];
      routePath.forEach((routeRole: any) => {
        if (this.cookieService.get('tipoParticipante').toLowerCase() == routeRole.toLowerCase()) {
          if(this.cookieService.get('tipoParticipante').toLowerCase() === TIPO_PARTICIPANTE.BBM.toLowerCase()){
            permission = true
            // if(routeRoles.length>0){
            //   routeRoles.forEach((role: string)=>{
            //     if(role == this.cookieService.get('perfilParticipante').toLowerCase()){
            //       permission = true;
            //     }
            //   })
            // }
          } else if(this.cookieService.get('tipoParticipante').toLowerCase() === TIPO_PARTICIPANTE.Corretora.toLowerCase()){
            permission = true;
          } else {
            permission = false;
          }
        }
      });
      if (!permission) { this.router.navigateByUrl('/error/nao-autorizado'); }
    } else {
      this.router.navigateByUrl('/error/nao-autorizado');
    }
    return permission;
  }
}
