import { KeycloakService } from 'keycloak-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CriptoLocalStorageService } from '../../../shared/services/cripto-localstorage/cripto-localstorage.service';



@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  public userPictureOnly: boolean = false;
  public user: any;
  public userName: any;
  public tokenInfo: any;

  public currentTheme = 'default';
  public userMenu = [{title:'Seleção de Participantes'},  { title: 'Sair' } ];

  public env = environment;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private cookieService: CriptoLocalStorageService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              protected router: Router, private keycloak: KeycloakService ) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.tokenInfo = this.keycloak.getKeycloakInstance().idTokenParsed;

    

    this.user = {
      name: this.tokenInfo.nome,
      picture: 'assets/images/user.svg'
    };

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.title === "Sair") {
        this.logout();
      }
      if (event.item.title === "Seleção de Participantes") {
        this.router.navigateByUrl("/usuario");
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }



  navigateHome() {
    this.router.navigateByUrl('/usuario');
    return false;
  }

  logout() {
    localStorage.removeItem("auth_app_token");
    this.cookieService.deleteAll()
    this.keycloak.logout(this.env.frontRef + '/');
    //this.router.navigateByUrl("/auth/login");
  }

}
