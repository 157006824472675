import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {
  private env = environment;
  constructor() { }
 
  //The set method is use for encrypt the value.
  set(keys: string, value: string){
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  async get(encryptedValue: string) {
    let key = this.env.keyCripto;
    // Convert the base64 encoded string to a byte array
    const encryptedBytes = new Uint8Array(atob(encryptedValue).split('').map(char => char.charCodeAt(0)));

    // Extract IV from the beginning of the byte array
    const iv = encryptedBytes.slice(0, 16);

    // Extract the encrypted data (excluding the IV)
    const encryptedData = encryptedBytes.slice(16);

    // Convert the key to bytes
    const keyBytes = new TextEncoder().encode(key);

    // Import the key and initialize the crypto operation
    const cryptoKey = await crypto.subtle.importKey('raw', keyBytes, 'AES-CBC', false, ['decrypt']);
    const decryptedData = await crypto.subtle.decrypt({ name: 'AES-CBC', iv }, cryptoKey, encryptedData);

    // Convert the decrypted data to a string
    const decryptedValue = new TextDecoder().decode(decryptedData);

    return decryptedValue;
  }
}