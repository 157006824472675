import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import { MainModule } from './main/main.module';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { AuthGuard } from './shared/guards/auth.guard';
import { UsuarioComponent } from './usuario/usuario.component';
import { RolesGuard } from './shared/guards/roles.guard';
import { PathGuard } from './shared/guards/path.guard';


export const routes: Routes = [
  { path: '', redirectTo: 'usuario', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.NgxAuthModule)
  },
  {
    path: 'usuario', 
    loadChildren: () => import('./usuario/usuario.module').then(m => m.UsuarioModule), 
    canActivate: [AuthGuard]
  },
  {
    path: 'main', 
    loadChildren: () => import('./main/main.module').then(m => m.MainModule), 
    canActivate: [RolesGuard, PathGuard], data: { roles: ['administrador', 'operador','diretor','operacao','comercial'], path: ['bbm', 'corretora']}
  },
  {
    path: 'ramo-atividade', 
    loadChildren: () => import('./main/crud-ramo-atividade/crud-ramo-atividade.module').then(m => m.CrudRamoAtividadeModule), 
    canActivate: [RolesGuard, PathGuard], data: { roles: ['administrador','diretor','operacao'], path: ['bbm']}
  },
  {
    path: 'error', 
    loadChildren: () => import('./miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule)
  },
  { path: 'participantes', loadChildren: () => import('./main/participantes/participantes.module').then(m => m.ParticipantesModule) },
  { path: 'pessoa-juridica', loadChildren: () => import('./main/participantes/pessoa-juridica/pessoa-juridica.module').then(m => m.PessoaJuridicaModule) },
 
  { path: '**', redirectTo: 'error' },
];

const config: ExtraOptions = {
    useHash: false,
    relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
