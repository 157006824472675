import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
}
  from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { SISTEMAS } from '../../../shared/enums/sistemas';
import { environment } from 'environments/environment';
import { CriptoLocalStorageService } from '../../../shared/services/cripto-localstorage/cripto-localstorage.service';
import { KeycloakService } from 'keycloak-angular';
@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private cookieService: CriptoLocalStorageService,
    private keycloak: KeycloakService
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var _participanteID = this.cookieService.get('uniqueIdParticipante');
    var tokenInfo = this.keycloak.getKeycloakInstance().idTokenParsed;
    let dupReq:any;
    if (request.url.startsWith(environment.buscaCepApi)) {
      dupReq = request.clone({
        headers: request.headers
      });
    }else{
      dupReq = request.clone({
        headers: request.headers
          .set('x-participant-id', (_participanteID ? _participanteID : ""))
          .set('usuario-id', (tokenInfo?.sub ? tokenInfo?.sub : ""))
          .set('Sistema', SISTEMAS.CadastroParticipantesAdmin)
      });
    }
    return next.handle(dupReq);
  }
}
