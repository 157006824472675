import { Injectable } from '@angular/core';
import { SmartTableData } from '../data/smart-table';

@Injectable()
export class SmartTableService extends SmartTableData {

  data = [{
    id: 1,
    firstName: 'Frango Natto',
    CNPJ: '12345678911',
    email: 'mdo@gmail.com',
  }, {
    id: 2,
      firstName: 'Frango Natto',
      CNPJ: '12345678911',
      email: 'mdo@gmail.com',
  }, {
    id: 3,
      firstName: 'Frango Natto',
      CNPJ: '12345678911',
      email: 'mdo@gmail.com',
  }, {
    id: 4,
      firstName: 'Frango Natto',
      CNPJ: '12345678911',
      email: 'mdo@gmail.com',
  }, {
    id: 5,
      firstName: 'Frango Natto',
      CNPJ: '12345678911',
      email: 'mdo@gmail.com',
  }, {
    id: 6,
      firstName: 'Frango Natto',
      CNPJ: '12345678911',
      email: 'mdo@gmail.com',
  }];

  getData() {
    return this.data;
  }
}
