import { Globals } from './../globals';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UsuarioService } from '../services/usuario.service';
import { environment } from 'environments/environment';
import { CriptoLocalStorageService } from '../services/cripto-localstorage/cripto-localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard  {



  private logged = false;
  private acessos: any;
  public env = environment;

  constructor( private usuario: UsuarioService,private cookieService: CriptoLocalStorageService,protected router: Router, private globals: Globals, protected readonly keycloak: KeycloakService){ 
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }
    
    this.acessos = this.keycloak.getKeycloakInstance().resourceAccess;

    let user:any = this.keycloak.getKeycloakInstance().idTokenParsed;   
    this.usuario.getParticipantesUsuario(user.sub).subscribe((data)=>{
      
      if(data.autorizacoes.length == 0 || !data.autorizacoes){
        this.keycloak.logout(
          this.env.frontRef + '/error/nao-autorizado'
        );

      }
    },(error)=>{
      this.keycloak.logout(
        this.env.frontRef + '/error/nao-autorizado'
      );
    })


    // Get the roles required from the route.
     
    const requiredRoles = route.data.roles;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }

  checkLogin(): boolean {
    if (!this.globals.auth) {
      this.router.navigate(['/auth/login']);
    }

    return this.globals.auth ? true : false;
  }

}
