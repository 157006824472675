import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { TIPO_PARTICIPANTE } from '../enums/tipoParticipante.enum';
import { CriptoLocalStorageService } from './cripto-localstorage/cripto-localstorage.service';



@Injectable({
  providedIn: 'root'
})
export class UsuarioService implements OnInit{

  public naturezaOrgaoPromotor: any;
  public env = environment
  public tipoPessoa : string = ''
  public corretoraPart = false;
  public corretoraId: string = ''
  public nomeParticipante: Subject<string> = new Subject<string>();

  ngOnInit(){
    
  }
  

  constructor(private http: HttpClient, private cookieService: CriptoLocalStorageService) {}

  public getParticipantesUsuario(idParticipante: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/Usuario/'+idParticipante+'/participantes');
  }

  public getUsuariosOrgaoPromotor(idParticipante: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/admin/orgaopromotor/' + idParticipante+'/Usuarios');
  }

  public deleteUsuarioOrgaoPromotor(orgaoPromotorUniqueId:any, usuarioUniqueId: string): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/orgaoPromotor/'+ orgaoPromotorUniqueId +'/Autorizacao/Usuario/'+usuarioUniqueId);
  }

  public UsuarioOrgaoPromotorAtivar(orgaoUniqueId:any, usuarioUniqueId:any){
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/orgaopromotor/'+orgaoUniqueId+'/Ativar/Usuario/'+usuarioUniqueId, {});
  }

  public UsuarioOrgaoPromotorDesativar(orgaoUniqueId:any, usuarioUniqueId:any){
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/orgaopromotor/'+orgaoUniqueId+'/Inativar/Usuario/'+usuarioUniqueId, {});
  }
  public mudarStatusUsuarioOrgaoPromotor(body: any): Observable<any> {
    return this.http.put(this.env.api + '/api/admin/orgaopromotor/usuario/status', body);
  }

  public mudarStatusUsuarioOrgaoPromotorUnidadeCompradoraAtivar(orgaoPromotorUniqueId: any,unidadeCompradoraUniqueId: any,usuarioUniqueId: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/orgaoPromotor/'+orgaoPromotorUniqueId+'/UnidadeCompradora/'+unidadeCompradoraUniqueId+'/Ativar/Usuario/'+usuarioUniqueId, {});
  }
  public mudarStatusUsuarioOrgaoPromotorUnidadeCompradoraInativar(orgaoPromotorUniqueId: any,unidadeCompradoraUniqueId: any,usuarioUniqueId: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/orgaoPromotor/'+orgaoPromotorUniqueId+'/UnidadeCompradora/'+unidadeCompradoraUniqueId+'/Inativar/Usuario/'+usuarioUniqueId, {});
  }

  public adicionarUsuariosOrgaoPromotor(id: any,body:any, usuarioUniqueId?:any): Observable<any> {
    return usuarioUniqueId? this.http.post(this.env.apiCadastroParticipantes + '/api/admin/orgaopromotor/'+id+'/Autorizacao/Usuario/'+usuarioUniqueId, body) :
    this.http.post(this.env.apiCadastroParticipantes + '/api/admin/orgaopromotor/'+id+'/Autorizacao/Usuario', body)
  }

  public adicionarUsuariosOrgaoPromotorUnidadeCompradora(body:any,orgaoPromotorUniqueId:any,unidadeCompradoraUniqueId:any): Observable<any> {
   return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/orgaoPromotor/'+orgaoPromotorUniqueId+'/UnidadeCompradora/'+unidadeCompradoraUniqueId+'/Autorizacao/Usuario', body)
  }

  public adicionarUsuarioExistenteOrgaoPromotorUnidadeCompradora(body:any,orgaoPromotorUniqueId:any,unidadeCompradoraUniqueId:any,usuarioUniqueId:any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/orgaoPromotor/'+orgaoPromotorUniqueId+'/UnidadeCompradora/'+unidadeCompradoraUniqueId+'/Autorizacao/Usuario/' + usuarioUniqueId, body)
   }

  public atualizarUsuariosOrgaoPromotor(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/orgaopromotor/'+body.orgaoPromotorUniqueId+'/Usuario/'+body.usuarioUniqueId, body);
  }
  
  public atualizarUsuariosOrgaoPromotorUnidadeCompradora(body: any,orgaoPromotorUniqueId: any,unidadeCompradoraUniqueId: any,usuarioUniqueId: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/orgaoPromotor/'+orgaoPromotorUniqueId+'/UnidadeCompradora/'+unidadeCompradoraUniqueId+'/Usuario/'+usuarioUniqueId, body);
  }

  public atualizarPermissoesOrgaoPromotor(orgaoUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any, perfilUniqueId:any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/orgaoPromotor/'+orgaoUniqueId+'/Usuario/'+usuarioUniqueId+'/Sistema/'+sistemaUniqueId+'/Perfil/'+perfilUniqueId, {});
  }

  public adicionaPermissoesOrgaoPromotorUnidadeCompradora(orgaoPromotorUniqueId:any, unidadeCompradoraUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any,perfilUniqueId:any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/orgaoPromotor/'+orgaoPromotorUniqueId+'/UnidadeCompradora/'+unidadeCompradoraUniqueId+'/Usuario/'+usuarioUniqueId+'/Sistema/'+sistemaUniqueId+'/Perfil/'+perfilUniqueId, {});
  }

  public atualizarPermissoesPF(clientePfUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any,perfilUniqueId:any): Observable<any> {
    if(this.cookieService.get('tipoParticipante') == TIPO_PARTICIPANTE.Corretora){
      this.tipoPessoa = this.cookieService.get('tipoPessoa')
      this.corretoraPart = true;
      this.corretoraId = this.cookieService.get('uniqueIdParticipante')
    } 
    if(this.corretoraPart){
      return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/pf/'+clientePfUniqueId+'/Usuario/'+usuarioUniqueId+'/Sistema/'+sistemaUniqueId+'/Perfil/'+perfilUniqueId, {});
    }else{
    return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/cliente/pf/'+clientePfUniqueId+'/Usuario/'+usuarioUniqueId+'/Sistema/'+sistemaUniqueId+'/Perfil/'+perfilUniqueId, {});
  }
}

public atualizarPermissoesCorretoraPF(corretoraPfUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any,perfilUniqueId:any): Observable<any> {
  return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/corretora/pf/'+corretoraPfUniqueId+ '/Usuario/'+ usuarioUniqueId+'/Sistema/'+sistemaUniqueId+'/Perfil/'+perfilUniqueId, {})
}

public deletarPermissoesCorretoraPF(corretoraPfUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any,perfilUniqueId:any): Observable<any> {
  return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/corretora/pf/'+corretoraPfUniqueId+ '/Usuario/'+ usuarioUniqueId+'/Sistema/'+sistemaUniqueId+'/Perfil/'+perfilUniqueId, {})
}

  public deletarPermissoesOrgaoPromotor(orgaoUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any, perfilUniqueId:any): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/orgaoPromotor/'+orgaoUniqueId+'/Usuario/'+usuarioUniqueId+'/Sistema/'+sistemaUniqueId+'/Perfil/'+perfilUniqueId, {});
  }

  public deletarPermissoesOrgaoPromotorUnidadeCompradora(orgaoPromotorUniqueId:any, unidadeCompradoraUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any,perfilUniqueId:any): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/orgaoPromotor/'+orgaoPromotorUniqueId+'/UnidadeCompradora/'+unidadeCompradoraUniqueId+'/Usuario/'+usuarioUniqueId+'/Sistema/'+sistemaUniqueId+'/Perfil/'+perfilUniqueId, {});
  }
  public deletarUsuarioOrgaoPromotorUnidadeCompradora(orgaoPromotorUniqueId:any, unidadeCompradoraUniqueId:any, usuarioUniqueId:any): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/orgaoPromotor/'+orgaoPromotorUniqueId+'/UnidadeCompradora/'+unidadeCompradoraUniqueId+'/Autorizacao/Usuario/'+usuarioUniqueId, {});
  }

  public getUsuariosParticipante(idParticipante: string): Observable<any> {
    if(this.cookieService.get('tipoParticipante') == TIPO_PARTICIPANTE.Corretora){
      this.tipoPessoa = this.cookieService.get('tipoPessoa')
      this.corretoraPart = true;
      this.corretoraId = this.cookieService.get('uniqueIdParticipante')
    }

    if(this.corretoraPart){
      return this.http.get(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/pj/'+idParticipante+'/Usuarios');
    }else{
      return this.http.get(this.env.apiCadastroParticipantes + '/api/admin/cliente/pj/'+idParticipante+'/Usuarios');
    }
  }

  public getUsuariosParticipanteEstrangeiro(idParticipante: string): Observable<any> {
    if(this.cookieService.get('tipoParticipante') == TIPO_PARTICIPANTE.Corretora){
      this.tipoPessoa = this.cookieService.get('tipoPessoa')
      this.corretoraPart = true;
      this.corretoraId = this.cookieService.get('uniqueIdParticipante')
    }

    if(this.corretoraPart){
      return this.http.get(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/estrangeira/'+idParticipante+'/Usuarios');
    }else{
      return this.http.get(this.env.apiCadastroParticipantes + '/api/admin/cliente/estrangeira/'+idParticipante+'/Usuarios');
    }
  }

  public getUsuariosCorretora(corretoraPjUniqueId: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/admin/corretora/pj/'+corretoraPjUniqueId+'/Usuarios');
  }

  public deleteUsuarioParticipante(clientePjUniqueId: string, usuarioUniqueId: any): Observable<any> {
    if(this.corretoraPart){
      return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/pj/' + clientePjUniqueId + '/Autorizacao/Usuario/' + usuarioUniqueId);
    }else{
      return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/cliente/pj/' + clientePjUniqueId + '/Autorizacao/Usuario/' + usuarioUniqueId);
    }
  }

  public deleteUsuarioParticipanteEstrangeiro(clientePjUniqueId: string, usuarioUniqueId: any): Observable<any> {
    if(this.corretoraPart){
      return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/estrangeira/' + clientePjUniqueId + '/Autorizacao/Usuario/' + usuarioUniqueId);
    }else{
      return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/cliente/estrangeira/' + clientePjUniqueId + '/Autorizacao/Usuario/' + usuarioUniqueId);
    }
  }

  public mudarStatusUsuarioParticipanteAtivar(clientePjUniqueId: string, usuarioUniqueId: any): Observable<any> {
    if(this.corretoraPart){
      return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/' +this.corretoraId+ '/cliente/pj/'+clientePjUniqueId+ '/Ativar/Usuario/' +usuarioUniqueId, {})
    }else{
      return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/cliente/pj/' + clientePjUniqueId + '/Ativar/Usuario/' + usuarioUniqueId, {});
    }
  }

  public mudarStatusUsuarioParticipanteEstrangeiroAtivar(clienteEstrangeiraUniqueId: string, usuarioUniqueId: any): Observable<any> {
    if(this.corretoraPart){
      return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/' +this.corretoraId+ '/cliente/estrangeira/'+clienteEstrangeiraUniqueId+ '/Ativar/Usuario/' +usuarioUniqueId, {})
    }else{
      return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/cliente/estrangeira/' + clienteEstrangeiraUniqueId + '/Ativar/Usuario/' + usuarioUniqueId, {});
    }
  }

  public mudarStatusUsuarioParticipanteDesativar(clientePjUniqueId: string, usuarioUniqueId: any): Observable<any> {
    if(this.corretoraPart){
      return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/' +this.corretoraId+ '/cliente/pj/'+clientePjUniqueId+ '/Inativar/Usuario/' +usuarioUniqueId, {})
    }else{
        return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/cliente/pj/' + clientePjUniqueId + '/Inativar/Usuario/' + usuarioUniqueId, {});
    }
  }

  public mudarStatusUsuarioParticipanteEstrangeiroDesativar(clienteEstrangeiraUniqueId: string, usuarioUniqueId: any): Observable<any> {
    if(this.corretoraPart){
      return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/' +this.corretoraId+ '/cliente/estrangeira/'+clienteEstrangeiraUniqueId+ '/Inativar/Usuario/' +usuarioUniqueId, {})
    }else{
        return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/cliente/estrangeira/' + clienteEstrangeiraUniqueId + '/Inativar/Usuario/' + usuarioUniqueId, {});
    }
  }

  public deleteUsuarioCorretora(corretoraPjUniqueId: string, usuarioUniqueId: string): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/corretora/pj/'+corretoraPjUniqueId+'/Autorizacao/Usuario/'+usuarioUniqueId);
  }

  public mudarStatusUsuarioParticipante(body: any): Observable<any> {
    return this.http.put(this.env.api + '/api/admin/cliente/usuario/status', body);
  }

  public mudarStatusUsuarioCorretoraAtivar(corretoraPjUniqueId: any,usuarioUniqueId:any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/corretora/pj/'+corretoraPjUniqueId+'/Ativar/Usuario/'+usuarioUniqueId, {});
  }

  public mudarStatusUsuarioCorretoraDesativar(corretoraPjUniqueId: any,usuarioUniqueId:any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/corretora/pj/'+corretoraPjUniqueId+'/Inativar/Usuario/'+usuarioUniqueId, {});
  }


  public adicionarUsuariosParticipante(clientePjUniqueId: any, body: any, usuarioUniqueId?: any): Observable<any> {
    if(this.corretoraPart){
      if(usuarioUniqueId){
        return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/pj/' +clientePjUniqueId+'/Autorizacao/Usuario/'+usuarioUniqueId, body)
      }
      else{
        return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/pj/' +clientePjUniqueId+'/Autorizacao/Usuario', body)
      }
    }else{
      if(usuarioUniqueId){
        return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/cliente/pj/' +clientePjUniqueId+'/Autorizacao/Usuario/'+usuarioUniqueId, body)
      }else { 
        return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/cliente/pj/'+clientePjUniqueId+'/Autorizacao/Usuario', body);
      }
    }
  }

  public adicionarUsuariosParticipanteEstrangeiro(clientePjUniqueId: any, body: any, usuarioUniqueId?: any): Observable<any> {
    if(this.corretoraPart){
      if(usuarioUniqueId){
        return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/estrangeira/' +clientePjUniqueId+'/Autorizacao/Usuario/'+usuarioUniqueId, body)
      }
      else{
        return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/estrangeira/' +clientePjUniqueId+'/Autorizacao/Usuario', body)
      }
    }else{
      if(usuarioUniqueId){
        return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/cliente/estrangeira/' +clientePjUniqueId+'/Autorizacao/Usuario/'+usuarioUniqueId, body)
      }else { 
        return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/cliente/estrangeira/'+clientePjUniqueId+'/Autorizacao/Usuario', body);
      }
    }
  }

  public adicionarUsuariosCorretora(corretoraPjUniqueId: any, body: any,usuarioUniqueId?:any): Observable<any> {
    return usuarioUniqueId?
        this.http.post(this.env.apiCadastroParticipantes + '/api/admin/corretora/pj/'+corretoraPjUniqueId+'/Autorizacao/Usuario/'+usuarioUniqueId, body):
        this.http.post(this.env.apiCadastroParticipantes + '/api/admin/corretora/pj/'+corretoraPjUniqueId+'/Autorizacao/Usuario', body);
  }

  public atualizarUsuariosParticipante(body: any): Observable<any> {
    if(this.corretoraPart){
      return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/pj/'+body.clientePjUniqueId+'/Usuario/'+body.usuarioUniqueId, body)
    }else{
      return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/cliente/pj/'+body.clientePjUniqueId+'/Usuario/'+body.usuarioUniqueId, body)
    }
  }

  public atualizarUsuariosParticipanteEstrangeiro(body: any): Observable<any> {
    if(this.corretoraPart){
      return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/estrangeira/'+body.clientePjUniqueId+'/Usuario/'+body.usuarioUniqueId, body)
    }else{
      return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/cliente/estrangeira/'+body.clientePjUniqueId+'/Usuario/'+body.usuarioUniqueId, body)
    }
  }

  public atualizarUsuariosCorretora(idParticipante: any, idUsuario: any, body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/corretora/pj/' + idParticipante + '/usuario/' + idUsuario, body);
  }

  public atualizarPermissoesParticipante(body: any): Observable<any> {
    return this.http.put(this.env.api + '/api/admin/usuario/' + body.id + '/perfil/' + body.perfilId +'/adicionar', {});
  }
  public atualizarPermissoesParticipantePJ(clientePjUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any, perfilUniqueId:any): Observable<any> {
    if(this.corretoraPart){
      return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/pj/' + clientePjUniqueId + '/Usuario/' + usuarioUniqueId + '/Sistema/' +sistemaUniqueId+ '/Perfil/' +perfilUniqueId, {});
    }else{
      return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/cliente/pj/' + clientePjUniqueId + '/Usuario/' + usuarioUniqueId + '/Sistema/' +sistemaUniqueId+ '/Perfil/' +perfilUniqueId, {});
    }
  }
  
  public atualizarPermissoesParticipanteEstrangeiro(clientePjUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any, perfilUniqueId:any): Observable<any> {
    if(this.corretoraPart){
      return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/estrangeira/' + clientePjUniqueId + '/Usuario/' + usuarioUniqueId + '/Sistema/' +sistemaUniqueId+ '/Perfil/' +perfilUniqueId, {});
    }else{
      return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/cliente/estrangeira/' + clientePjUniqueId + '/Usuario/' + usuarioUniqueId + '/Sistema/' +sistemaUniqueId+ '/Perfil/' +perfilUniqueId, {});
    }
  }

  public atualizarPermissoesParticipanteCorretora(corretoraPjUniqueId: any,usuarioUniqueId: any,sistemaUniqueId: any,perfilUniqueId: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/corretora/pj/'+corretoraPjUniqueId+'/Usuario/'+usuarioUniqueId+'/Sistema/'+sistemaUniqueId+'/Perfil/'+perfilUniqueId, {});
  }
  
  public deletePermissaoParticipantePJ(clientePjUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any, perfilUniqueId:any): Observable<any> {
    if(this.corretoraPart){
      return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/pj/' + clientePjUniqueId + '/Usuario/' + usuarioUniqueId + '/Sistema/' +sistemaUniqueId+ '/Perfil/' +perfilUniqueId, {});
    }else{
      return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/cliente/pj/' + clientePjUniqueId + '/Usuario/' + usuarioUniqueId + '/Sistema/' +sistemaUniqueId+ '/Perfil/' +perfilUniqueId, {});
    }
  }

  public deletePermissaoParticipanteEstrangeiro(clientePjUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any, perfilUniqueId:any): Observable<any> {
    if(this.corretoraPart){
      return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/estrangeira/' + clientePjUniqueId + '/Usuario/' + usuarioUniqueId + '/Sistema/' +sistemaUniqueId+ '/Perfil/' +perfilUniqueId, {});
    }else{
      return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/cliente/estrangeira/' + clientePjUniqueId + '/Usuario/' + usuarioUniqueId + '/Sistema/' +sistemaUniqueId+ '/Perfil/' +perfilUniqueId, {});
    }
  }
  
  public deletePermissaoParticipantePF(clientePjUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any, perfilUniqueId:any): Observable<any> {
    if(this.cookieService.get('tipoParticipante') == TIPO_PARTICIPANTE.Corretora){
      this.tipoPessoa = this.cookieService.get('tipoPessoa')
      this.corretoraPart = true;
      this.corretoraId = this.cookieService.get('uniqueIdParticipante')
    }
    if(this.corretoraPart){
      return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/corretora/'+this.tipoPessoa+'/'+this.corretoraId+'/cliente/pf/' + clientePjUniqueId + '/Usuario/' + usuarioUniqueId + '/Sistema/' +sistemaUniqueId+ '/Perfil/' +perfilUniqueId, {});
    }else{
      return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/cliente/pf/' + clientePjUniqueId + '/Usuario/' + usuarioUniqueId + '/Sistema/' +sistemaUniqueId+ '/Perfil/' +perfilUniqueId, {});
  }
}


  public deletePermissaoParticipante(body: any): Observable<any> {
    return this.http.put(this.env.api + '/api/admin/usuario/' + body.id + '/perfil/' + body.perfilId +'/remover', {});
  }
  public deletePermissaoParticipanteCorretora(corretoraPjUniqueId: any,usuarioUniqueId: any,sistemaUniqueId: any,perfilUniqueId: any): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/corretora/pj/'+corretoraPjUniqueId+'/Usuario/'+usuarioUniqueId+'/Sistema/'+sistemaUniqueId+'/Perfil/'+perfilUniqueId, {});
  }

  public atualizarPermissoesCorretora(body: any): Observable<any> {
    return this.http.put(this.env.api + '/api/admin/usuario/' + body.id + '/perfil/' + body.perfilId +'/adicionar', {});
  }

  public deletePermissaoCorretora(body: any): Observable<any> {
    return this.http.put(this.env.api + '/api/admin/usuario/' + body.id + '/perfil/' + body.perfilId +'/remover', {});
  }




  public getUsuariosBBM(idParticipante: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/admin/bbm/'+idParticipante+'/Usuarios');
  }

  public deleteUsuarioBBM(idUsuario: string, idParticipante: any): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/bbm/'+idParticipante+'/Autorizacao/Usuario/' + idUsuario);
  }

  public mudarStatusUsuarioBBMAtivar(bbmUniqueId:any, usuarioUniqueId:any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/bbm/'+bbmUniqueId+ '/Ativar/Usuario/' + usuarioUniqueId, {});
  }

  public mudarStatusUsuarioBBMDesatvar(bbmUniqueId:any, usuarioUniqueId:any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/bbm/'+bbmUniqueId+ '/Inativar/Usuario/' + usuarioUniqueId, {});
  }

  public  adicionarUsuariosBBM(body: any, bbmUniqueId:any, usuarioUniqueId? :any): Observable<any> {
    if(usuarioUniqueId){
      return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/bbm/' +bbmUniqueId+ '/Autorizacao/Usuario/'+usuarioUniqueId, body);
    }else{
      return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/bbm/' +bbmUniqueId+ '/Autorizacao/Usuario', body);
    }
  }

  public atualizarUsuariosBBM(body: any, bbmUniqueId:any, usuarioUniqueId:any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/bbm/'+bbmUniqueId+'/Usuario/'+usuarioUniqueId, body);
  }

  public atualizarPermissoesBBM(bbmUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any, perfilUniqueId:any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/bbm/' +bbmUniqueId+ '/Usuario/' +usuarioUniqueId+ '/Sistema/'  +sistemaUniqueId+ '/Perfil/' + perfilUniqueId, {})
  }

  public deletePermissaoBBM(bbmUniqueId:any, usuarioUniqueId:any, sistemaUniqueId:any, perfilUniqueId:any): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/bbm/' +bbmUniqueId+ '/Usuario/' +usuarioUniqueId+ '/Sistema/'  +sistemaUniqueId+ '/Perfil/' + perfilUniqueId, {})
  }



}
